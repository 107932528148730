import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Button, Footer, Header, IconSvg } from 'src/components/common';
import styles from './index.module.scss';
import { Icons } from 'src/types/icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AuthContext } from 'src/contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import { OurMemberSayMobile } from 'src/components/home/OurMemberSayMobile';
import { PlansMobile } from 'src/components/home/PlansMobile';
import { useHttp, useNotification } from 'src/hooks';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';
import { OtherMemberSayTablet } from 'src/components/home/OtherMemberSayTablet';
import { XploriusRiskFreeModal } from 'src/components/home/modal/XploriusRiskFreeModal';
import { SelectComponent } from 'src/components/common/form';

interface HowItWorks {
  img: Icons;
  imgSmall: Icons;
  description: ReactNode;
}
interface MembersBookedHotel {
  img: Icons;
  savedParcentage: string;
  rate: string;
  location: string;
  totalHotels: string;
}

interface Testimonials {
  name: string;
  location: string;
  message: ReactNode;
  icon: Icons;
}
interface Currency {
  id: string;
  name: string;
  code: string;
  symbol: string;
  isActive: boolean;
}

interface PlanDetail {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
}

interface Plan {
  id: string;
  name: string;
  amount: number;
  priceId: string;
  createdAt: string;
  updatedAt: string;
  details: PlanDetail[];
  currency: Currency;
}

export function HomePage() {
  const [selecFaq, setSelectFaq] = useState<string[]>(['1']);
  const { loginWithRedirect, userAuth0, accessToken } = useContext(AuthContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { sendRequest, isLoading: isLoading, error } = useHttp();
  const notify = useNotification();
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [currencyOptions, setCurrencyOptions] = useState<{ label: string; value: string }[]>([]);
  const [defaultCurrency, setDefaultCurrency] = useState<{ label: string; value: string }>();
  const [prices, setPrices] = useState<any>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  const getPrices = () => {
    if (defaultCurrency?.value) {
      sendRequest(
        {
          url: `payment/prices?currencyId=${defaultCurrency?.value}`,
          method: 'GET',
        },
        (data: any) => {
          setPrices(data);
        },
      );
    }
  };
  const getCurrencies = () => {
    sendRequest(
      {
        url: 'currency',
        method: 'GET',
      },
      (data: any) => {
        setCurrencies(data);

        const options = data?.map((currency: Currency) => ({
          label: `${currency.code}`,
          value: currency.id,
        }));
        setCurrencyOptions(options);

        if (options.length > 0) {
          setDefaultCurrency(options[0]);
        }
      },
    );
  };
  useEffect(() => {
    getCurrencies();
  }, []);
  useEffect(() => {
    getPrices();
  }, [defaultCurrency?.value]);

  const handleCurrencyChange = (input: { name: string; value: string }) => {
    const selectedOption = currencyOptions.find(option => option.value === input.value);
    if (selectedOption) {
      setDefaultCurrency(selectedOption);
    }
  };

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  const renderContentCrypto = (count: number) => {
    const content = [];
    for (let i = 0; i <= count; i++) {
      content.push(
        <div key={i} className={styles['pattern']}>
          <p className="uppercase font-inter">BOOK WITH CARD OR CRYPTO</p>
        </div>,
      );
    }
    return content;
  };

  const renderContent = (count: number) => {
    const content = [];
    for (let i = 0; i <= count; i++) {
      content.push(
        <div key={i} className={styles['pattern']}>
          <p className="uppercase font-inter">STOP OVERPAYING FOR HOTELS</p>
        </div>,
      );
    }
    return content;
  };
  const renderSubscription = (count: number) => {
    const content = [];
    for (let i = 0; i <= count; i++) {
      content.push(
        <div key={i} className={styles['pattern']}>
          <p className="uppercase font-inter">SECURE EARLY-BIRD PRICE BEFORE IT&apos;S gone!</p>
        </div>,
      );
    }
    return content;
  };
  const aboutUs = (count: number) => {
    const content = [];
    for (let i = 0; i <= count; i++) {
      content.push(
        <div key={i} className={styles['pattern']}>
          <p className="uppercase font-inter">the best kept secret in travel</p>
        </div>,
      );
    }
    return content;
  };

  const handleButtonClick = (buttonText: string, planTitle: string) => {
    if (buttonText === 'SIGN UP') {
      const matchingPlan = prices.find((plan: any) => plan.name === planTitle);

      signupHandlerWithObject(matchingPlan);
    } else if (buttonText === 'TALK TO SALES') {
      window.open(
        'https://api.whatsapp.com/message/6GFBB37QHD6UN1?autoload=1&app_absent=0',
        '_blank',
      );
    }
  };

  const signupHandlerWithObject = (plan?: Plan) => {
    if (accessToken) {
      navigate('/profile');
    } else if (defaultCurrency && plan) {
      const { priceId, amount: planAmount, currency, name: planTitle } = plan;

      const currencyId = defaultCurrency.value; // Assuming `defaultCurrency` holds the ID
      navigate(
        `/sign-up?step=2&planAmount=${planAmount}&currency=${currency.code}&currencyId=${currencyId}&planId=${priceId}&planTitle=${planTitle}`,
      );
    } else {
      navigate('/sign-up');
    }
  };

  const signupHandler = () => {
    if (accessToken) {
      navigate('/profile');
    } else {
      navigate('/sign-up');
    }
  };

  const handleNewsletterSubscribe = (email: string) => {
    sendRequest(
      {
        method: 'POST',
        url: 'mailchimp/subscribe',
        accessToken,
        payload: { email },
      },
      res => {
        notify('You have successfully subscribed to our newsletter!', 'success');
        setEmail('');
      },
    );
  };

  const testimonials: Testimonials[] = [
    {
      name: 'Rohallah',
      location: 'London, UK',
      message: (
        <p>
          I{`'`}ve
          <span className="font-bold font-inter ml-1 ">
            saved 522 USD on my first booking with XPLORIUS.
          </span>{' '}
          With such savings right out of the gate, I{`'`}m already convinced that the annual
          subscription fee was a wise investment.
        </p>
      ),
      icon: 'dummyAvatar',
    },
    {
      name: 'Luka & Maria',
      location: 'Dubai, UAE',
      message: (
        <p>
          The value is undeniable. What truly sets XPLORIUS apart is{' '}
          <span className="font-bold font-inter">the exceptional customer service,</span> always
          willing to go the extra mile for the members.
        </p>
      ),
      icon: 'dummyAvatar2',
    },
    {
      name: 'Muhammad',
      location: 'Toronto, Canada',
      message: (
        <p>
          I can&apos;t believe how much I was overpaying for hotels before discovering XPLORIUS.A
          big bonus for me personally is the ability to
          <span className="font-bold font-inter ml-1">
            choose between paying in fiat or crypto.
          </span>
        </p>
      ),
      icon: 'dummyAvatar3',
    },
  ];
  const handleCalculateNowClick = () => {
    window.location.href = 'https://xplorius.involve.me/xplorius-calculator';
  };
  const faqs = [
    {
      id: '1',
      question: 'What kind of savings can I expect on the platform?',
      answer:
        'On average, our members save 35% per booking compared to publicly available hotels rates, but discounts can vary from 5% to 70%, depending on the hotel, destination, and dates.',
    },
    {
      id: '2',
      question: 'What are public or online rates you’re comparing XPLORIUS rates to?',
      answer:
        'Public, also known as “online”, rates are the ones listed openly on the hotels’ websites or popular hotel booking platforms. With XPLORIUS, you’re benefiting from secret hotel deals hidden from the general public.',
    },
    {
      id: '3',
      question: 'How is XPLORIUS different from other  popular hotel booking platforms?',
      answer:
        'XPLORIUS curates unbeatable hidden hotel deals from across the globe. Additionally, we provide top-notch client service to ensure that every one of your trips is seamless and enjoyable.',
    },
    {
      id: '4',
      question: 'Why should I subscribe?',
      answer:
        'By subscribing to XPLORIUS, you can save hundreds, if not thousands, on your annual travel expenses.The more you travel, the more you save!',
    },
    {
      id: '5',
      question: 'Can I book directly on XPLORIUS?',
      answer: 'Indeed, you can book hotels directly on our platform in just a few clicks.',
    },
    {
      id: '6',
      question: 'What payment options are available on the platform?',
      answer: 'Our platform supports all major currencies. You can also pay in cryptocurrency.',
    },
    {
      id: '7',
      question: "I don't travel that much, will I still benefit from signing up?",
      answer: (
        <span>
          Even if you travel once a year, you&apos;re likely to make your money back from booking a
          single trip! Calculate how much you can save with XPLORIUS{' '}
          <span
            className="cursor-pointer border-[#4A4A4A] border-b "
            onClick={handleCalculateNowClick}
          >
            here
          </span>
          .
        </span>
      ),
    },
    {
      id: '8',
      question: "Won't if be cheaper for me to book a hotel directly on the hotel's website?",
      answer:
        'This is a very common misconception that booking directly on the hotel’s website is always “the best rate guarantee”. This is only applicable to publicly available hotel rates, but you can do so much better than that with our hidden deals!',
    },
    {
      id: '9',
      question: 'What kind of hotels can I book on XPLORIUS?',
      answer:
        'Almost any hotel you can think of! We have over 2 million properties worldwide that you can book directly on the platform.',
    },
    {
      id: '10',
      question: 'Is there a limit on how many hotel bookings I can make on XPLORIUS?',
      answer:
        'At the moment, we don’t have a cap on how many bookings you’re allowed to make throughout the year. Have fun with it!',
    },
    {
      id: '11',
      question: 'Will you increase the price of the subscriptions?',
      answer:
        "Yes, our subscription price and model will be evolving along with the platform. Secure the early-bird price before it's gone!",
    },
    {
      id: '12',
      question: 'What are XPLORIUS membership options and cancellation policy?',
      answer:
        'We offer biannual and annual memberships with the flexibility to cancel anytime before the renewal date, no strings attached. For a smooth cancellation, we advise initiating the process at least 7 days prior. Please note that cancellations apply to upcoming subscriptions only. Memberships auto-renew unless cancelled. For more details, please read our Terms of sale & Terms of use.',
    },
    {
      id: '13',
      question:
        'I am looking to book a specific hotel, but I can’t find it on the platform. Can you help me out?',
      answer:
        'We always go an extra mile for our members! Get in touch with our client service team and we’ll look into all available opportunities.',
    },
    // {
    //   id: '11',
    //   question: 'I have a special travel request, can you help me with it?',
    //   answer:
    //     'On average, our clients save 35% on online hotel rates, but discounts can vary from 5% to 70%, depending on the hotel, destination, and dates.',
    // },
  ];

  const howItWorksData: HowItWorks[] = [
    {
      img: 'step1',
      imgSmall: 'step1Png',
      description: (
        <p className="font-inter">
          Purchase XPLORIUS <span className="font-bold font-inter">membership</span> to unlock
          secret hotel
          <br className="lg:block hidden" /> deals.
        </p>
      ),
    },
    {
      img: 'step2',
      imgSmall: 'step2Png',
      description: (
        <p className="font-inter">
          Browse from a selection of{' '}
          <span className="font-bold font-inter">2 million hotels worldwide</span>
          <br className="lg:block hidden" /> available on the platform - that&apos;s virtually any
          popular
          <br className="lg:block hidden" /> hotel you can think of!
        </p>
      ),
    },
    {
      img: 'step3',
      imgSmall: 'step3Png',
      description: (
        <p className="font-inter">
          Book your room and pay in <span className="font-bold font-inter">fiat</span> or{' '}
          <span className="font-bold font-inter">crypto.</span>
        </p>
      ),
    },
  ];
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);

  const settings = {
    centerMode: true,
    centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    centerHeight: '460px',
    centerWidth: '760px',
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => setCurrentIndex(newIndex),
    prevArrow: <IconSvg icon="leftLongArrowGreen" className="w-[44px] h-[25px]" />,
    nextArrow: <IconSvg icon="rightLongArrowGreen" className="w-[44px] h-[25px]" />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsMobileScreen = {
    centerMode: true,
    centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    centerHeight: '227px',
    centerWidth: '300px',
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div className="arrow" onClick={() => sliderRef && sliderRef.slickPrev()}>
        <IconSvg icon="leftLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
    nextArrow: (
      <div className="arrow " onClick={() => sliderRef && sliderRef.slickNext()}>
        <IconSvg icon="rightLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
  };

  const sliderData: MembersBookedHotel[] = [
    {
      img: 'hotel3',
      savedParcentage: '42%',
      location: 'Tokyo, Japan',
      rate: '591 USD',
      totalHotels: '4* hotel, Chiyoda City',
    },
    {
      img: 'hotel4',
      savedParcentage: '15%',
      location: 'Los Angeles, USA',
      rate: '555 USD',
      totalHotels: '5* hotel, Beverly Hills',
    },
    {
      img: 'hotel5',
      savedParcentage: '35%',
      location: 'Dubai, UAE',
      rate: '1,249 USD',
      totalHotels: '5* hotel, JBR',
    },
    {
      img: 'hotel6',
      savedParcentage: '32%',
      location: 'Lisbon, Portugal',
      rate: '825 USD',
      totalHotels: '5* hotel, Downtown',
    },
    {
      img: 'hotel7',
      savedParcentage: '73%',
      location: 'Dubai, UAE',
      rate: ' 799 USD',
      totalHotels: '5* hotel, DIFC area',
    },
    {
      img: 'hotel8',
      savedParcentage: '25%',
      location: 'Crete, Greece ',
      rate: '6,470 USD',
      totalHotels: '5* resort, Chania',
    },
    {
      img: 'hotel9',
      savedParcentage: '35%',
      location: 'Istanbul, Turkey',
      rate: '297 USD',
      totalHotels: '5* hotel, Sultanahmet',
    },
    {
      img: 'hotel10',
      savedParcentage: '45%',
      location: 'Seattle, USA',
      rate: '312 USD',
      totalHotels: '4* hotel, Downtown',
    },
    {
      img: 'hotel11',
      savedParcentage: '69%',
      location: 'El Gouna, Egypt',
      rate: '691 USD',
      totalHotels: '5* hotel, El Gouna',
    },

    {
      img: 'hotel12',
      savedParcentage: '38%',
      location: 'Bali, Indonesia',
      rate: '355 USD',
      totalHotels: '5* hotel, Nusa Dua ',
    },

    {
      img: 'hotel13',
      savedParcentage: '45%',
      location: 'Singapore',
      rate: '521 USD',
      totalHotels: '5* hotel, Central Business District',
    },

    {
      img: 'hotel14',
      savedParcentage: '44%',
      location: 'South of France',
      rate: '9,610 USD',
      totalHotels: '5* hotel, Roquebrune-Cap-Martin',
    },
  ];

  const membershipPlans = [
    {
      title: 'BIANNUAL',
      features: [
        'Unrestricted access for 6 months to our premium hotel booking platform, 24/7',
        'No cap on the number of hotel bookings',
        'Billed every 6 months',
        'Cancel your next subscription anytime, no strings attached',
      ],
      price: (prices[0]?.amount || '') + ' ' + (prices[0]?.currency?.code || ''),
      buttonText: 'SIGN UP',
      colorScheme: '#4A4A4A',
      isHighlighted: false,
    },
    {
      title: 'YEARLY',
      features: [
        'Unrestricted access for a whole year to our premium hotel booking platform, 24/7',
        'No cap on the number of hotel bookings',
        'Billed once a year',
        'Cancel your next subscription anytime, no strings attached',
      ],
      price: (prices[1]?.amount || '') + ' ' + (prices[1]?.currency?.code || ''),
      buttonText: 'SIGN UP',
      colorScheme: '#C56B48',
      isHighlighted: false,
    },
    {
      title: 'ENTERPRISE',
      features: [
        'Include XPLORIUS in your employee benefits scheme',
        'Unlock unlimited discounted business trips',
        'Receive dedicated B2B terms',
      ],
      price: '.',
      buttonText: 'TALK TO SALES',
      colorScheme: '#C56B48',
      isHighlighted: true,
    },
  ];
  const faqSectionRef = useRef<HTMLDivElement>(null);
  const mpSectionRef = useRef<HTMLDivElement>(null);
  const scrollToMP = () => {
    if (mpSectionRef.current) {
      window.scrollTo({
        top: mpSectionRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const scrollToFaq = () => {
    if (faqSectionRef.current) {
      window.scrollTo({
        top: faqSectionRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };
  const howItWorksRef = useRef(null);
  const [isOpenRiskFreeModal, setIsOpenRiskFreeModal] = useState(false);
  const xploriusRiskFreeModalHandler = () => {
    setIsOpenRiskFreeModal(prev => !prev);
  };

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem('viewRiskFreeModal');
    if (!hasSeenModal) {
      const membershipPricingSection = document.getElementById('membership-pricing');
      const membershipPricingSectionMobile = document.getElementById('membership-pricing-mobile');

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            xploriusRiskFreeModalHandler();
            sessionStorage.setItem('viewRiskFreeModal', 'true');
            observer.disconnect();
          }
        },
        { threshold: 0.5 },
      );

      if (membershipPricingSection) {
        observer.observe(membershipPricingSection);
      }

      if (membershipPricingSectionMobile) {
        observer.observe(membershipPricingSectionMobile);
      }

      return () => observer.disconnect();
    }
  }, []);

  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = '/public/assets/images/muslimGirlWithHat.png';
  }, []);

  return (
    <>
      <XploriusRiskFreeModal
        isOpen={isOpenRiskFreeModal}
        onCancel={xploriusRiskFreeModalHandler}
        img="/assets/images/muslimGirlWithHat.png"
      />
      <p className="lg:hidden block bg-[#C56B48] uppercase font-inter font-medium text-sm leading-4 py-3 text-[#F5F5F5] text-center">
        BOOK WITH CARD OR CRYPTO
      </p>
      <div className="flex flex-col justify-center items-center w-full ">
        <div className="w-full bg-[#EFE8DF] ">
          <Header scrollToFaq={scrollToFaq} scrollToMP={scrollToMP} />
          <div className="sm:block hidden">
            <div className="sm:block hidden relative lg:px-10 px-2.5">
              <div className="flex justify-between lg:space-x-10 space-x-0 2xl:pt-[50px] pt-5">
                <div className="flex flex-col lg:mt-[66px] sm:mt-0 relative z-10">
                  <div className="flex items-center space-x-2">
                    <IconSvg icon="snowMountain" className="w-[190px] lg:block hidden" />
                    <p className="2xl:text-6xl xl:text-5xl lg:text-5xl md:lg:text-4xl text-3xl text-[#0F0F0F] font-medium font-inter">
                      BOOK YOUR
                    </p>
                  </div>
                  <div className="flex flex-col mt-2">
                    <p className="2xl:text-6xl xl:text-5xl lg:text-5xl md:lg:text-4xl text-3xl text-[#0F0F0F] font-medium font-inter">
                      FAVORITE HOTELS
                    </p>
                    <div className="flex items-center space-x-1">
                      <p className="2xl:text-6xl xl:text-5xl lg:text-5xl md:lg:text-4xl text-3xl text-[#0F0F0F] font-medium font-inter">
                        AT
                      </p>
                      <div className="flex">
                        <p className="bg-[#3E7A63] px-1 text-[#EFE8DF] rounded 2xl:text-6xl xl:text-5xl lg:text-5xl md:lg:text-4xl text-3xl font-medium font-inter">
                          UP TO 70% OFF
                        </p>
                      </div>
                    </div>
                    <p className="flex mt-1 2xl:text-6xl xl:text-5xl lg:text-5xl md:lg:text-4xl text-3xl text-[#0F0F0F] font-medium font-inter">
                      PUBLIC RATES.
                      <IconSvg icon="arrow4" />
                      <IconSvg icon="arrowBack" />
                    </p>
                  </div>

                  <p className="text-[#4A4A4A] lg:text-xl text-base font-medium lg:mt-5 mt-[17px] lg:mb-8 mb-[17px] font-inter leading-[22.4px]">
                    UNLOCK SECRET HOTEL DEALS WITH <br className="2xl:hidden block" /> XPLORIUS
                    MEMBERSHIP.
                  </p>
                  <Button
                    color="yellow"
                    size="medium"
                    className="lg:w-[271px] w-[173px] text-2xl h-[50px]"
                    label={
                      <p className="lg:text-2xl text-lg leading-[21.6px] font-medium">SIGN UP</p>
                    }
                    onClick={signupHandler}
                  />
                </div>
                <div className="lg:flex hidden items-center justify-end 2xl:gap-10 gap-4">
                  <IconSvg
                    icon="landingPageTopRight1Jpg"
                    className="h-full 2xl:w-[350px] xl:w-[290px] lg:w-[290px] md:w-[180px] sm:w-[170px] w-[145px] rounded-tl-[100px] rounded-tr-[100px] rounded-b-[5px]"
                  />
                  <IconSvg
                    icon="landingPageTopRight2Jpg"
                    className="h-full 2xl:w-[350px] xl:w-[290px] lg:w-[290px] w-[145px] rounded-tl-[100px] rounded-tr-[5px] rounded-b-[5px]"
                  />
                </div>
                <div className="lg:hidden flex items-center justify-end 2xl:gap-10 gap-4">
                  <IconSvg icon="landingPageTopRight1MobilePng" className="h-full w-[145px]" />
                  <IconSvg
                    icon="landingPageTopRight2MobilePng"
                    className="h-full w-[145px] rounded-tl-[100px] rounded-tr-[2px] rounded-b-[2px]"
                  />
                </div>
              </div>
              <div className="lg:block hidden absolute w-full left-0 4xl:-bottom-[160px] 3xl:-bottom-[75px] 2xl:-bottom-[37px] -bottom-[37px] z-0">
                <IconSvg className="w-full" icon="curveRoundedLine" />
              </div>
              <div className="lg:hidden block absolute w-full left-0 lg:-bottom-28 -bottom-[54px] z-0">
                <IconSvg className="w-full" icon="curveRoundedLineMd" />
              </div>
            </div>

            <div className="flex justify-center  lg:mt-[100px] mt-[50px] ">
              <p className=" text-black lg:text-lg text-base font-inter font-medium flex items-center gap-2.5">
                Proudly born in Dubai, UAE{' '}
                <span>
                  <IconSvg icon="uaeFlag" className="h-4 w-4" />
                </span>
              </p>
            </div>
          </div>
          {/* This section for mobile screen start */}
          <div className="sm:hidden block ">
            <div className="w-full h-full px-4">
              <div className="flex items-end w-full gap-2.5">
                <div className="w-full h-full">
                  <IconSvg
                    icon="landingPageTopRight1MobilePng"
                    className="w-full rounded-t-[100px] rounded-b-[2px]"
                  />
                </div>
                <div className="w-full">
                  <IconSvg
                    icon="landingPageTopRight2MobilePng"
                    className="w-full rounded-tl-[100px] rounded-tr-[2px] rounded-b-[2px]"
                  />
                </div>
              </div>
            </div>
            <div className="relative h-[252px]">
              <div className="mt-[32px] mb-5 px-4">
                <p className=" text-[26px] leading-[33.8px] font-medium font-inter text-[#0F0F0F]">
                  BOOK YOUR FAVORITE
                  <br /> HOTELS AT
                  <br />
                  <span className="bg-[#3E7A63] text-[#EFE8DF] text-[26px] leading-[33.8px] font-medium font-inter px-2 rounded">
                    UP TO 70% OFF
                  </span>
                  <br />
                  PUBLIC RATES.
                </p>
              </div>

              <p className="text-[#4A4A4A] text-sm font-medium pl-4 font-inter">
                UNLOCK SECRET HOTEL DEALS WITH
                <br /> XPLORIUS MEMBERSHIP.
              </p>
              {/* <div className="absolute bottom-0 w-full">
                <IconSvg className="w-full" src="/assets/images/curveRoundedLineSmall.svg" />
              </div> */}
            </div>
            <div className="flex justify-center items-center -mt-[20px]">
              <Button
                color="yellow"
                size="medium"
                className="w-[150px] sm:text-2xl text-sm"
                label={'SIGN UP'}
                onClick={() => signupHandler()}
              />
            </div>
            <div className="flex justify-center mt-5">
              <p className="text-[#0F0F0F] font-inter font-medium text-[10px] flex items-center gap-2.5">
                Proudly born in Dubai, UAE{' '}
                <span>
                  <IconSvg icon="uaeFlag" className="h-4 w-4" />
                </span>
              </p>
            </div>
          </div>

          <div
            className={`${styles['black-block']} bg-[#C56B48] items-center mt-[100px] lg:block hidden`}
          >
            <div className={styles['ticker-wrapper__first-half']}>{renderContentCrypto(14)}</div>
          </div>

          {/* This section for mobile screen end */}
          <div className="lg:pt-[120px] sm:pt-[50px] pt-[32px] font-medium font-inter lg:px-10 px-[10.5px] ">
            <h3 className="uppercase lg:text-[40px] sm:text-[26px] text-[24px] text-[#0F0F0F] sm:pl-0 font-medium lg:leading-[47.2px] sm:leading-[30.68px] leading-[28.32px]  font-inter">
              Real deals members booked
            </h3>
            <div className="w-full sm:block hidden">
              <div className="slick-slider-container-home lg:pt-[40px] sm:pt-5 lg:px-7  px-5">
                <Slider {...settings}>
                  {sliderData.map((item, i) => (
                    <>
                      <div key={i}>
                        <div className="slider-item">
                          <IconSvg icon={item.img} />
                          <div className="text-overlay">{item.location}</div>
                          <div className="bottom-text-overlay font-inter">
                            SAVED {item.savedParcentage}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </Slider>
              </div>
              <div className="flex justify-center items-center w-full mt-[7px] rate-and-total-hotels">
                <div>
                  <p className="font-inter lg:text-lg sm:text-sm font-light text-[#4A4A4A] text-center">
                    {`{`}or <span className="font-bold">{sliderData[currentIndex].rate}</span>{' '}
                    compared to public rates{`}`}
                  </p>
                  <p className="font-inter lg:text-lg font-light text-[#0F0F0F] text-center pt-[15px] !mt-0">
                    {sliderData[currentIndex].totalHotels}
                  </p>
                </div>
              </div>
            </div>

            {/* This slider screen for mobile start */}
            <div className="w-full sm:hidden block">
              <div className="slick-slider-container-home-mobile lg:pt-10 sm:pt-5 relative">
                <Slider {...settingsMobileScreen} ref={slider => setSliderRef(slider)}>
                  {sliderData.map((item, i) => (
                    <div key={i}>
                      <div className="slider-item-mobile ">
                        <IconSvg icon={item.img} className="rounded-[5px] !w-full !h-[227px]" />
                        <div className="text-overlay-mobile">{item.location}</div>
                        <div className="bottom-text-overlay-mobile !font-bold">
                          SAVED {item.savedParcentage}
                        </div>
                      </div>
                      <div className="flex justify-center items-center w-full mt-7">
                        <div>
                          <p className="font-inter  lg:text-lg sm:text-sm font-light text-[#4A4A4A]">
                            {`{`}or <span className="font-bold">{item.rate}</span> compared to
                            public rates{`}`}
                          </p>
                          <p className="lg:text-lg  font-light font-inter text-[#0F0F0F] text-center">
                            {item.totalHotels}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
                <div className="slick-arrows flex items-center justify-between absolute bottom-[50px] w-full">
                  {settingsMobileScreen.prevArrow}
                  {settingsMobileScreen.nextArrow}
                </div>
              </div>
            </div>
            {/* This slider screen for mobile end */}
          </div>
          <div className="lg:pt-[60px] sm:pt-[50px] pt-[32px] lg:px-10 px-2.5">
            <h3 className="uppercase lg:text-[40px] sm:text-[26px] text-[24px] text-[#0F0F0F] font-medium font-inter">
              THIS PLATFORM IS FOR YOU IF
            </h3>
            <div className="flex lg:flex-row flex-col lg:items-center lg:justify-between justify-center lg:space-x-5 lg:space-y-0 space-y-3 lg:mt-[40px] mt-5 mb-5">
              <div className="lg:h-[184px] sm:h-36 h-auto lg:flex-1 lg:p-8 p-5 rounded-2xl border-[0.5px] border-[#959595]">
                <p className="font-inter lg:text-2xl text-xl font-semibold text-[#0F0F0F] mb-[14px]">
                  You fancy 4* or 5* hotels.
                </p>
                <p className="font-inter lg:text-lg text-sm font-normal text-[#4A4A4A] m-0">
                  You’ll likely cover your membership fee - or even make a profit - on your very
                  first booking!
                </p>
              </div>
              <p className="font-inter font-normal text-lg text-[#4A4A4A] text-center">OR</p>
              <div className="lg:h-[184px] sm:h-36 h-auto lg:flex-1 lg:p-8 p-5 rounded-2xl border-[0.5px] border-[#959595]">
                <p className="font-inter lg:text-2xl text-xl font-semibold text-[#0F0F0F] mb-[14px]">
                  You’re always on the go.
                </p>
                <p className="font-inter lg:text-lg text-sm font-normal text-[#4A4A4A] m-0">
                  Spending 25+ nights per year in a hotel room? Even with budget-friendly stays
                  under $200 per night, you’ll see at least 2x ROI.
                </p>
              </div>
            </div>

            <div className="flex lg:flex-row flex-col items-center justify-center lg:space-x-8 mt-12 mb-8">
              <IconSvg icon="circlePlatform" className="lg:w-auto w-40" />
              <p className="font-inter text-[#4A4A4A] lg:text-2xl sm:text-lg text-sm text-center font-normal lg:mt-0 mt-10 lg:leading-[50px] sm:leading-[36px] leading-[36px]">
                <span className="lg:text-[32px]  sm:text-[28px] text-xl font-bold">95%</span> of
                members recover their subscription cost with the first{' '}
                <br className="sm:block hidden" /> booking, saving an average of{' '}
                <span className="lg:text-[32px] sm:text-[28px] text-xl font-bold">30%</span> per
                trip and gaining <br className="sm:block hidden" />
                <span className="lg:text-[32px] sm:text-[28px] text-xl font-bold">15x</span> their
                membership fee value through our exclusive offers.
              </p>
            </div>
          </div>
          <div
            className="lg:pt-[60px] sm:pt-[50px] pt-[32px] lg:px-10 px-2.5"
            ref={howItWorksRef}
            id="how-it-works"
          >
            <h3 className="uppercase lg:text-[40px] sm:text-[26px] text-[24px] text-[#0F0F0F] font-medium font-inter">
              how it works
            </h3>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-10 sm:gap-2.5 lg:mt-[40px] sm:mt-5 mb-5">
              {howItWorksData.map((how, i) => (
                <div
                  className={`w-full ${
                    i === 2 ? 'lg:col-span-1 sm:col-span-3 col-span-1 lg:mt-0 sm:mt-[30px]' : ''
                  }`}
                  key={i}
                >
                  <IconSvg className={`w-full lg:block hidden rounded-[10px]`} icon={how.img} />
                  {i !== 2 && (
                    <IconSvg
                      className={`w-full lg:hidden sm:block hidden  rounded-[10px]`}
                      icon={how.imgSmall}
                    />
                  )}
                  {i == 2 && (
                    <IconSvg
                      className={`w-full lg:hidden sm:block hidden  rounded-[10px]`}
                      icon={'step3TabletPng'}
                    />
                  )}
                  <p className="lg:mt-[30px] sm:mt-5 mt-10 lg:text-[30px] text-lg leading-tight text-[#000000] font-inter">
                    Step {i + 1}
                  </p>
                  <p className="lg:text-lg sm:text-base text-sm text-[#000000] pt-2.5 sm:pb-0 pb-4 font-inter">
                    {how.description}
                  </p>

                  {i + 1 == 3 && (
                    <>
                      <div className="lg:flex hidden lg:flex-wrap sm:flex-nowrap flex-wrap sm:items-center items-center gap-[15px] sm:w-80 w-full sm:mt-5 sm:mb-0 mb-5">
                        <IconSvg className="sm:w-auto w-[28px] h-[40px]" icon="bitCoin" />
                        <IconSvg className="sm:w-auto w-[26px] h-[42px]" icon="ethereum" />
                        <IconSvg className="sm:w-auto w-[38px] h-[38px]" icon="usdCoin" />
                        <IconSvg className="sm:w-auto w-[38px] h-[38px]" icon="apeCoin" />
                        <IconSvg className="sm:w-auto w-[38px] h-[38px]" icon="liteCoin" />
                        <IconSvg className="sm:w-auto w-[43px] h-[38px]" icon="polygon" />
                        <IconSvg className="sm:w-auto w-[71px] h-[24px]" icon="visa" />
                        <IconSvg className="sm:w-auto w-[59px] h-[58px]" icon="masterCard" />
                        <IconSvg className="sm:w-auto w-[48px] h-[49px]" icon="americanExpress" />
                        <IconSvg className="sm:w-auto w-[54px] h-[34px]" icon="discover" />
                      </div>
                      <div className=" lg:hidden flex lg:flex-wrap sm:flex-nowrap flex-wrap sm:items-center items-center gap-[15px] sm:w-80 w-full sm:mt-5 sm:mb-0 mb-5">
                        <IconSvg className="w-[28px] h-10" icon="bitCoinMobilePng" />
                        <IconSvg className="w-[26px] h-[42px]" icon="ethereumMobilePng" />
                        <IconSvg className="w-[38px] h-[38px]" icon="usdCoinMobilePng" />
                        <IconSvg className="w-[38px] h-[38px]" icon="apeCoinMobilePng" />
                        <IconSvg className="w-[38px] h-[38px]" icon="liteCoinMobilePng" />
                        <IconSvg className="w-[43px] h-[38px]" icon="polygonMobilePng" />
                        <IconSvg className="w-[71px] h-6" icon="visaMobilePng" />
                        <IconSvg className="w-[59px] h-[58px]" icon="masterCardMobilePng" />
                        <IconSvg className="w-12 h-[49px]" icon="americanExpressMobilePng" />
                        <IconSvg className="w-[54px] h-[34px]" icon="discoverMobilePng" />
                      </div>
                    </>
                  )}
                  <IconSvg className="w-full rounded-[10px] sm:hidden block" icon={how.imgSmall} />
                </div>
              ))}
            </div>
          </div>
          <div
            className={`${styles['black-block']} bg-[#C56B48] items-center mt-[100px] lg:block hidden`}
          >
            <div className={styles['ticker-wrapper__first-half']}>{renderContent(14)}</div>
          </div>

          <div className="lg:mt-[125.09px] sm:mt-[50px] mt-[32px] lg:px-10 sm:px-[10px] px-[11px]  font-medium font-inter leading-tight sm:block hidden">
            <h3 className="uppercase font-inter font-medium text-[#0F0F0F] lg:text-[40px] sm:text-[26px] text-[24px] sm:block hidden">
              message from our member
            </h3>
            <h3 className="uppercase font-inter text-[#0F0F0F] lg:text-[40px] text-[24px] sm:hidden block">
              OUR MEMBERS SAY
            </h3>
            <div className="lg:pt-12 sm:pt-7 pt-6 sm:flex-row sm:items-start flex flex-col-reverse lg:gap-10 gap-4 lg:text-[40px] text-xl">
              <IconSvg
                icon="dummyUserPic"
                className="lg:h-[352px] h-full lg:w-[350px] sm:w-[300px] w-full lg:order-1 order-2"
              />
              <div className="w-full font-light lg:order-2 order-1 ">
                <div className="lg:leading-[48px] leading-6 font-inter lg:text-[40px] text-[20px]">
                  <IconSvg
                    icon="horizontalDoubleHeadLongArrow"
                    className="lg:w-[145px] w-[92px] inline-block"
                  />
                  <span className="pl-2">
                    Here&apos;s a secret!{' '}
                    {/* <span className="bg-[#C56B48] text-[#EFE8DF] font-inter rounded-[10px] lg:p-1 px-2 py-1 font-bold inline-block"> */}
                    Public hotel rates are old news -{/* </span> */}
                  </span>
                  &nbsp;as a luxury traveller, I&apos;m saving thousands of my travel dollars with
                  <span className="bg-[#C56B48] text-[#EFE8DF] rounded-[10px] font-inter px-2 py-1 font-bold inline-block">
                    XPLORIUS hidden hotel deals.
                  </span>{' '}
                  My first booking alone saved me 10 times more than what I paid for my annual
                  membership. XPLORIUS is a game-changer for anyone who loves to travel!{' '}
                </div>

                <p className="italic font-normal font-bigCaslon lg:mt-6 mt-[15px] text-xl sm:flex lg:justify-start sm:justify-center hidden">
                  - Lolita Piotrovskaya
                </p>
                <div className="sm:hidden block mt-4 mb-4">
                  <div className="flex items-center justify-between">
                    <IconSvg icon="leftLongArrowGreen" className="w-[37px]" />
                    <p className="font-bigCaslon italic font-normal text-base">
                      - Lolita Piotrovskaya
                    </p>
                    <IconSvg icon="rightLongArrowGreen" className="w-[37px]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* This is our member say slider component is for mobile start */}
          <div className=" sm:hidden block">
            <OurMemberSayMobile />
          </div>
          {/* This is our member say slider component is for mobile end */}
          <div className="lg:pt-[100px] sm:pt-[50px] pt-6 lg:px-10 px-2.5 font-inter">
            <h3 className="uppercase lg:text-[40px] sm:text-[26px] text-[24px] font-medium font-inter sm:leading-[47.2px] text-[#0F0F0F] sm:block hidden">
              Other members say
            </h3>
            <div className="w-full sm:block hidden">
              <div className="lg:grid lg:grid-cols-3 grid-cols-2 lg:gap-10 hidden gap-4 lg:mt-5 sm:mt-6 mt-5 ">
                {testimonials.map((testimonial, index) => (
                  <div
                    className="border-2  border-[#4A4A4A] rounded-xl pl-[30px] py-[30px] pr-[52px]"
                    key={index}
                  >
                    <p className=" lg:leading-[19.2px] sm:leading-tight text-base font-light font-inter text-[#4A4A4A] lg:h-[83px] sm:h-[114px]">
                      {testimonial.message}
                    </p>
                    <div className="flex gap-[29px] mt-5">
                      <div className="h-[95px] w-[95px] rounded-full">
                        <IconSvg icon={testimonial.icon} />
                      </div>
                      <div>
                        <p className="font-medium font-inter text-[#0F0F0F]">{testimonial.name}</p>
                        <p className="mt-0">{testimonial.location}</p>
                        <IconSvg className="mt-2" icon="stars" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="lg:hidden block">
                <OtherMemberSayTablet />
              </div>
            </div>
          </div>
          <div
            className="lg:pt-[100px] pt-10 lg:px-10 px-4 font-inter text-[#0F0F0F] font-light lg:block hidden"
            ref={mpSectionRef}
          >
            <h2 className="uppercase lg:text-[40px] sm:text-[26px] text-[24px] font-medium font-inter text-[#0F0F0F]">
              Membership & Pricing
            </h2>
            <div className="flex items-center justify-between">
              <p className="uppercase sm:text-xl text-base font-light pt-[21px] text-[#4A4A4A]">
                sign up & start saving <span className="font-bold font-inter">up to 70%</span> per
                booking
              </p>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <SelectComponent
                  id="currency-select"
                  name="currency"
                  options={currencyOptions}
                  onChange={handleCurrencyChange}
                  placeholder="Select a currency"
                  value={defaultCurrency}
                  wrapperStyles="flex items-center z-[1]"
                />
              )}
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-10 mt-10">
              {membershipPlans.map((plan, i) => (
                <div
                  className={`border rounded-[10px] relative ${
                    plan.isHighlighted ? 'border-[#4A4A4A]  bg-[#4A4A4A]' : 'border-[#C56B48]'
                  }`}
                  key={i}
                >
                  {i == 1 && (
                    <div className="absolute -top-5 right-4 flex items-center gap-2 bg-[#9B2626] py-2 px-3 rounded-[5px]">
                      <IconSvg icon="starCream" />
                      <p className="font-light font-inter text-sm text-[#EFE8DF] uppercase">
                        BEST VALUE{' '}
                      </p>
                    </div>
                  )}
                  <div
                    className={`flex justify-center rounded-t-lg items-center ${
                      plan.isHighlighted ? '!bg-[#4A4A4A] border-white border-b' : 'bg-[#C56B48]'
                    }`}
                  >
                    <p className="uppercase sm:py-[27px] py-3 sm:text-3xl text-[24px] font-medium font-inter text-white">
                      {plan.title}
                    </p>
                  </div>
                  <div className="sm:px-[29px] px-3 pt-4" id="membership-pricing">
                    <div className="sm:h-[220px]">
                      {plan.features.map((feature, j) => (
                        <div className="flex items-start sm:gap-4 gap-2.5 pt-5" key={j}>
                          <IconSvg
                            icon={plan.isHighlighted ? 'oDotWhiteSmall' : 'oDotSmallOrange'}
                          />
                          <p
                            className={`${
                              plan.isHighlighted ? 'text-[#EFE8DF]' : ''
                            } sm:text-lg text-base text-[#4A4A4A]`}
                          >
                            {feature}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-5 pb-[30px] justify-center items-center sm:mt-[60px] mt-[30px] ">
                      <h1 className="text-[28px] leading-tight">
                        <>
                          {i === 1 ? (
                            <>
                              {plan.price}
                              <span className="bg-[#4A4A4A] rounded-xl p-[10px] ml-2 text-white font-inter font-bold text-xl">
                                (SAVE 25%)
                              </span>
                            </>
                          ) : (
                            <span className={i === 2 ? 'text-[#4A4A4A]' : ''}>{plan.price}</span>
                          )}
                        </>
                      </h1>
                      <Button
                        color={i === membershipPlans.length - 1 ? 'orange' : 'yellow'}
                        size="medium"
                        label={
                          <p className="font-inter text-[22px] font-medium">{plan.buttonText}</p>
                        }
                        className="h-[53px] w-[271px]"
                        startIcon={plan.buttonText === 'TALK TO SALES'}
                        icon={
                          plan.buttonText === 'TALK TO SALES' ? (
                            <IconSvg icon="headPhone" />
                          ) : undefined
                        }
                        onClick={() => handleButtonClick(plan.buttonText, plan.title)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* This plans subscription section is for mobile start */}
          <div className="lg:hidden block" id="membership-pricing-mobile">
            <PlansMobile />
          </div>
          {/* This plans subscription section is for mobile end */}
          <div className={`${styles['black-block']} bg-[#9B2626] mt-[125px] lg:block hidden`}>
            <div className={styles['ticker-wrapper__first-half']}>{renderSubscription(14)}</div>
          </div>
          <div className="sm:hidden block bg-[#9B2626] rounded-[5px] mx-4 mt-14 p-6">
            <div className=" flex flex-col justify-center ">
              <div className="flex items-center justify-center mb-4">
                <IconSvg icon="sparrow" className="w-[31px] h-[31px]" />
              </div>
              <p className="font-inter text-base font-light text-[#EFE8DF] text-center leading-[18.88px]">
                Secure <span className="font-medium font-inter">early-bird price</span> before{' '}
                <br className="sm:hidden block" />
                it&apos;s gone!
              </p>
              {/* <p className="font-inter text-[18px] font-medium text-[#EFE8DF] uppercase pt-1 text-center">
                membership price
                <br className="sm:hidden block" /> increase on August 1, 2024.{' '}
              </p> */}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center sm:mt-24 mt-10">
            <div>
              {/* <IconSvg icon="oDotWithMountainsBackground" className="lg:w-auto w-[157px]" /> */}
              <div className="">
                {/* <IconSvg icon="oDotWIthsheepsBackground" /> */}
                <div className="lg:w-[271.99px] lg:h-[268.99px] w-[157px] h-[157px] bg-cover bg-center bg-no-repeat flex justify-center items-center rounded-full bg-[url(/public/assets/images/oDotWithMountainsBackground.jpg)] lg:py-[12px] lg:px-[27px] py-[8px] px-[15px]">
                  <div className="bg-[#EFE8DF] lg:w-[265px] lg:h-[256px] w-[151px] h-[145px] rounded-[50%] flex justify-center items-center ">
                    <div className="lg:w-[93.86px] lg:h-[93.11px] w-[54.18px] h-[54.34px] bg-cover bg-center bg-no-repeat rounded-full bg-[url(/public/assets/images/oDotWithMountainsBackground.jpg)]"></div>
                  </div>
                </div>
              </div>
            </div>
            <h1 className="uppercase lg:text-[80px] sm:text-[35px] text-4xl text-center lg:leading-tight leading-[42.31px] font-bigCaslon font-medium md:mt-10 sm:mt-5 mt-10">
              it pays to be a<br className="sm:hidden block" /> member
            </h1>
            <div className="flex items-center sm:px-0 px-[13.3px] w-full sm:mt-0 mt-5">
              {/* <div className="w-full sm:block hidden"></div> */}
              <div className="w-full flex flex-col justify-center items-center">
                <p className="lg:text-lg  text-base  font-inter font-light sm:mb-[21px] mb-10">
                  Calculate how much of your annual <br />
                  travel budget you could save with us.
                </p>
                <Button
                  color="primary"
                  size="medium"
                  label={
                    <p className="text-[#EFE8DF] lg:text-[22px] sm:text-xl text-base ">
                      CALCULATE NOW
                    </p>
                  }
                  className="lg:w-[322px] sm:w-[249px] w-[206.37px]"
                  onClick={handleCalculateNowClick}
                />
              </div>
            </div>
          </div>
          <div
            className="font-inter lg:pt-[100px] sm:pt-[50px] pt-10 lg:px-10  sm:px-2.5 px-4"
            ref={faqSectionRef}
            id="faq"
          >
            <h3 className="uppercase lg:text-[40px] sm:text-[30px] text-[24px] leading-tight text-[#0F0F0F] font-medium font-inter">
              FAQ
            </h3>
            <div className="mt-9">
              {faqs.map((faq, i) => (
                <div
                  className={`border-t border-[#959595] py-[30px] ${
                    i + 1 == faqs.length && 'md:border-b border-[#959595]'
                  }`}
                  key={i}
                >
                  {selecFaq.includes(faq.id) ? (
                    <div>
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => setSelectFaq(() => selecFaq.filter(item => item !== faq.id))}
                      >
                        <p className="font-bold font-inter text-[#0F0F0F] md:text-2xl sm:text-sm text-sm">
                          {faq.question}
                        </p>
                        <IconSvg
                          icon="cross"
                          className="lg:w-[22px] lg:h-[22px] sm:w-[10px]  sm:h-[10.04px] w-[10px] h-[10px] cursor-pointer"
                        />
                      </div>
                      <p className="lg:text-lg sm:text-sm text-sm font-light text-[#4A4A4A] mt-5 lg:w-[858px] w-full">
                        {faq.answer}
                      </p>
                    </div>
                  ) : (
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => setSelectFaq(() => [faq.id])}
                    >
                      <p className="font-bold text-[#0F0F0F] lg:text-2xl sm:text-sm text-sm font-inter">
                        {faq.question}
                      </p>
                      <IconSvg
                        icon="plus"
                        className="lg:w-[30px] lg:h-[30px] w-[10px] h-[10px] cursor-pointer"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* This section is commented by client start */}
          {/* <div className="sm:my-20  sm:block hidden">
            <div className="flex items-center justify-between h-[592px]">
              <IconSvg icon="curveLeftLine" className="lg:w-auto w-[300px]" />
              <div className="flex flex-col items-center">
                <IconSvg icon="travelInStyle" />
                <IconSvg icon="payLess" />
              </div>
              <IconSvg icon="curveRightLine" className="lg:w-auto w-[300px]" />
            </div>
          </div> */}
          {/* This section is for mobile screen  start*/}
          {/* <div className="my8  sm:hidden block py-10">
            <div className="flex items-center justify-between h-[186px]">
              <IconSvg icon="curveLeftLineSmall" />
              <div className="flex flex-col items-center">
                <p className="font-inter font-medium text-2xl text-center text-[#3E7A63] uppercase">
                  Travel in style more,
                </p>
                <div className="font-inter font-normal text-[33px] italic text-center text-[#3E7A63]">
                  pay less
                </div>
              </div>
              <IconSvg icon="curveRightLineSmall" />
            </div>
          </div> */}
          {/* This section is for mobile screen end*/}
          {/* This section is commented by client end */}
          <div className={`${styles['black-block']} bg-[#C56B48] lg:block hidden sm:mt-20`}>
            <div className={styles['ticker-wrapper__first-half']}>{aboutUs(14)}</div>
          </div>
          <div
            className="bg-cover h-[526px] cursor-default font-inter sm:block hidden bg-center bg-no-repeat"
            style={{ backgroundImage: `url("/assets/images/swimingBackground.jpg")` }}
          >
            <div className="flex flex-col gap-4 lg:px-10 sm:px-2.5 px-4 pt-[30px] items-start">
              <p className="text-[#EFE8DF] lg:text-[40px] text-[30px] font-medium font-inter uppercase leading-tight">
                stay in touch
              </p>
              <p className="text-[#EFE8DF] lg:text-lg text-base font-light lg:w-[365px] w-[330px] m-0">
                Sign up for our newsletter to stay updated on the best hotel deals worldwide.
              </p>
              <div className="flex justify-center items-center gap-1 lg:w-[365px] sm:w-[330px] w-full">
                <input
                  type="text"
                  placeholder="Your email"
                  className="w-[275px] h-10 py-[9px] px-[15px] bg-[#EFE8DF] outline-none rounded border-[#959595] border-2 z-10"
                  style={{ position: 'relative' }}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <Button
                  color="orange"
                  size="extra-small"
                  label={
                    <p className="text-[#EFE8DF] text-sm uppercase whitespace-nowrap">I’m in!</p>
                  }
                  className="sm:px-8 px-4 h-[36px] z-10"
                  onClick={() => handleNewsletterSubscribe(email)}
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-end w-full h-full pb-[220px]">
              <IconSvg className="w-full sm:block hidden" icon="curveRoundedLineWhite" />
            </div>
          </div>
          {/* this section for mobile screen */}
          <div
            className="bg-cover h-[748px] cursor-default font-inter sm:hidden block pb-10"
            style={{ backgroundImage: `url("/assets/images/swimingBackgroundSmallPng.png")` }}
          >
            <div className="flex flex-col  gap-5 lg:px-10 px-[14px] pt-[30px] items-start">
              <p className="text-[#EFE8DF] sm:text-[40px] text-[30px] font-medium font-inter uppercase leading-tight ">
                stay in touch
              </p>
              <p className="text-[#EFE8DF] sm:text-lg text-base font-light sm:w-[365px]">
                Sign up for our newsletter to stay
                <br /> updated on the best hotel deals
                <br /> worldwide.
              </p>
              <div className="flex justify-center items-center gap-2 w-full">
                <input
                  type="text"
                  placeholder="Your email"
                  className="w-full h-10 py-[9px] px-[15px] bg-[#EFE8DF] outline-none rounded-[5px] border-[#959595] border-[1px] "
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <Button
                  color="orange"
                  size="extra-small"
                  label={<p className="text-[#EFE8DF] text-sm ">I’M IN!</p>}
                  className="sm:px-8 px-0  h-[36px] w-[81px]"
                  onClick={() => handleNewsletterSubscribe(email)}
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-end w-full h-full pb-[180px]">
              <IconSvg className="w-full sm:hidden block" icon="curveRoundedLineWhiteSmall" />
            </div>
          </div>
          <div className=" sm:mt-[46px] mt-6 lg:mb-[46px] lg:px-10 px-[15.5px]">
            <Footer />
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
}
